<template>
    <div class="contractPriceForm">
        <!-- 基础信息 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="4" class="table-tit">
                            基础信息
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit" style="width:1.5rem;">
                            合同编号:
                        </td>
                        <td>{{ formData.contract_number }}</td>
                        <td class="column-tit" style="width:1.5rem;">
                            工程名称：
                        </td>
                        <td>{{ formData.engineering_name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 产品标号设置 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="4" class="table-tit">
                            产品标号设置
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit" style="width:1.5rem;">
                            产品标号
                        </td>
                        <td class="column-tit" style="width:1rem;">
                            价格(元)
                        </td>
                        <td class="column-tit">
                            附加型号
                        </td>
                        <td class="column-tit" style="width:1rem;">
                            操作
                        </td>
                    </tr>
                    <template v-if="formData.ContractPriceMarkNumList.length>0">
                        <tr
                            v-for="item in formData.ContractPriceMarkNumList"
                            :key="`productNum${item.cpmiid}`"
                        >
                            <td>
                                <el-input
                                    placeholder="—请选择—"
                                    v-model="item.product_mark_num"
                                    :disabled="vueFormParams.IsRead"
                                    readonly
                                >
                                <!-- <i slot="suffix" class="iconfont icon009"></i> -->
                                <!-- @focus="choseNumList(item)" -->
                                </el-input>
                            </td>
                            <td>
                                <el-input
                                    placeholder="请输入"
                                    type="number"
                                    v-model="item.product_mark_price"
                                    :disabled="vueFormParams.IsRead"
                                    @mousewheel.native.prevent
                                ></el-input>
                            </td>
                            <td>
                                <div>
                                    <ul class="tag-con clearfix">
                                        <template v-if="item.additionalList">
                                            <template v-for="(tag,tagindex) in item.additionalList">
                                                <li
                                                    class="tag el_left"
                                                    :key="`${item.product_mark_num}_${tag.cpaiid}_${tag.cpmiid}`"
                                                    v-if="tagindex<6"
                                                >
                                                    <span class="tag-text" :title="tag.additional_model">{{ tag.additional_model }}</span>
                                                    <span
                                                        class="iconfont iconguanbi"
                                                        @click="delLinkSingle(tag)"
                                                        v-if="!vueFormParams.IsRead"
                                                    ></span>
                                                </li>
                                            </template>
                                            <li class="tag el_left tag-more" @click="viewMoreTag(item)" v-if="item.additionalList.length>6">
                                                <span class="tag-more-text">查看更多</span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                            <td>
                                <div class="del-btn" @click="delMarkNum(item)" v-if="!vueFormParams.IsRead">
                                    删除
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="add-btn" @click="choseNumList" v-if="!vueFormParams.IsRead">
                <span class="iconfont icontianjia1"></span>
                <span>点击此处新增</span>
            </div>
        </div>
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="6" class="table-tit">
                            附加型号设置
                        </td>
                    </tr>
                    <template v-if="formData.ContractPriceAdditionalList.length>0">
                        <template v-for="item in formData.ContractPriceAdditionalList">
                            <tr :key="`additional${item.cpaiid}`">
                                <td class="column-tit" style="width:1.5rem;">
                                    附加型号
                                </td>
                                <td>
                                    <el-input
                                        placeholder="—请选择—"
                                        v-model="item.additional_model"
                                        :disabled="vueFormParams.IsRead"
                                        readonly
                                    >
                                    <!-- <i slot="suffix" class="iconfont icon009"></i> -->
                                    </el-input>
                                </td>
                                <td>
                                    <el-checkbox
                                        v-model="item.checkAll"
                                        :disabled="item.checkAllDisable || vueFormParams.IsRead"
                                        @change="handleCheckAll(item,$event)"
                                    >
                                        所有标号
                                    </el-checkbox>
                                </td>
                                <td class="column-tit" style="width:1.5rem;">
                                    价格
                                </td>
                                <td>
                                    <el-input
                                        placeholder="请输入"
                                        type="number"
                                        v-model="item.additional_model_price"
                                        :disabled="vueFormParams.IsRead"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                </td>
                                <td rowspan="2" v-if="!item.checkAll" style="width:1rem;">
                                    <div class="del-btn" @click="delAdditional(item)" v-if="!vueFormParams.IsRead">
                                        删除
                                    </div>
                                </td>
                                <td rowspan="1" v-if="item.checkAll" style="width:1rem;">
                                    <div class="del-btn" @click="delAdditional(item)" v-if="!vueFormParams.IsRead">
                                        删除
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!item.checkAll" :key="`link${item.cpaiid}`">
                                <td class="column-tit" style="width:1.5rem;">
                                    产品标号
                                </td>
                                <td colspan="4">
                                    <el-checkbox-group v-model="item.checkedProductNumList" class="checkgroup">
                                        <el-checkbox
                                            v-for="v in item.productNumChosed"
                                            :key="`link${item.cpaiid}${v.cpmiid}`"
                                            :label="v.cpmiid"
                                            :disabled="v.disable || vueFormParams.IsRead"
                                            @change="checkProductNum(item,v,$event)"
                                        >
                                            {{ v.product_mark_num }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
            <div class="add-btn" @click="choseAdditional" v-if="!vueFormParams.IsRead">
                <span class="iconfont icontianjia1"></span>
                <span>点击此处新增</span>
            </div>
        </div>
        <!-- 泵送价格 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="6" class="table-tit">
                            泵送价格
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            我方提供泵送:
                        </td>
                        <td>
                            <el-radio-group v-model="formData.provide_pump" :disabled="vueFormParams.IsRead">
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <td class="column-tit">
                            地泵费(元/m³):
                        </td>
                        <td style="width:1rem;" colspan="3">
                            <el-input
                                placeholder="请输入"
                                type="number"
                                v-model="formData.ground_pump_price"
                                :disabled="vueFormParams.IsRead"
                                @mousewheel.native.prevent
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            泵送补充模式：
                        </td>
                        <td colspan="1">
                            <el-radio-group v-model="formData.pump_charge_way" :disabled="vueFormParams.IsRead">
                                <el-radio :label="1">
                                    补方
                                </el-radio>
                                <el-radio :label="2">
                                    补固定金额
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <template v-if="formData.pump_charge_way===1">
                            <td class="column-tit" colspan="4">
                                不足<el-input
                                    placeholder="请输入"
                                    v-model="formData.pump_min"
                                    :disabled="vueFormParams.IsRead"
                                    type="number"
                                    class="line-input"
                                    @mousewheel.native.prevent
                                ></el-input> 方，
                                按<el-input
                                    placeholder="请输入"
                                    v-model="formData.pump_add"
                                    :disabled="vueFormParams.IsRead"
                                    type="number"
                                    class="line-input"
                                    @mousewheel.native.prevent
                                ></el-input>方结算
                            </td>
                        </template>
                        <template v-if="formData.pump_charge_way===2">
                            <td colspan="4">
                                不足<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.pump_volumn_less"
                                    type="number"
                                    @mousewheel.native.prevent
                                ></el-input>
                                方，收取<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.pump_plus_money"
                                    type="number"
                                    @mousewheel.native.prevent
                                ></el-input>元。
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td class="column-tit" colspan="6">
                            <el-radio-group v-model="formData.is_pump_base" :disabled="vueFormParams.IsRead">
                                <el-radio :label="true">
                                    统一泵送价格
                                    <el-input
                                        placeholder="请输入"
                                        class="line-input"
                                        v-model="formData.pump_price"
                                        type="number"
                                        :disabled="vueFormParams.IsRead||!formData.is_pump_base"
                                        @mousewheel.native.prevent
                                    ></el-input>
                                    <label>元/m³</label>
                                </el-radio>
                                <el-radio :label="false">
                                    泵送高度区间配置
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="column-tit">
                            泵送高度(m):
                        </td>
                        <td class="column-tit" colspan="1">
                            泵送价格(元/m³):
                        </td>
                        <td class="column-tit" colspan="3">
                            操作
                        </td>
                    </tr>
                    <tr v-for="pump in formData.ContractPricePumpList" :key="pump.cppiid">
                        <td colspan="2" class="pump-select">
                            <el-select
                                v-model="pump.pump_lower"
                                placeholder=""
                                :disabled="vueFormParams.IsRead||IsRead||formData.is_pump_base"
                            >
                                <el-option
                                    v-for="k in pumpingHeightSelectData"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                            -
                            <el-select
                                v-model="pump.pump_upper"
                                placeholder=""
                                :disabled="vueFormParams.IsRead||IsRead||formData.is_pump_base"
                            >
                                <el-option
                                    v-for="k in pumpingHeightSelectData"
                                    :disabled="judgePumpDisable(pump.pump_lower,k)"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td colspan="1">
                            <el-input
                                placeholder="请输入"
                                v-model="pump.pump_price"
                                :disabled="vueFormParams.IsRead||formData.is_pump_base"
                                type="number"
                                @mousewheel.native.prevent
                            ></el-input>
                        </td>
                        <td colspan="3">
                            <div class="del-btn" @click="delPumpPrice(pump)" v-if="!vueFormParams.IsRead&&!formData.is_pump_base">
                                删除
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="add-btn" @click="chosePumpList" v-if="!vueFormParams.IsRead&&!formData.is_pump_base">
                <span class="iconfont icontianjia1"></span>
                <span>点击此处新增</span>
            </div>
        </div>
        <!-- 运输费用设置 -->
        <div class="table-container">
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td colspan="6" class="table-tit">
                            运输费用设置
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit" style="width:1.5rem;">
                            我方运输：
                        </td>
                        <td class="column-tit">
                            是否含运费：
                        </td>
                        <td colspan="4">
                            <el-radio-group v-model="formData.is_included_freight" :disabled="vueFormParams.IsRead">
                                <el-radio :label="true">
                                    是
                                </el-radio>
                                <el-radio :label="false">
                                    否
                                </el-radio>
                            </el-radio-group>
                        </td>
                    <!-- <template v-if="formData.is_self_carriage">
                        <td class="column-tit">
                            价格(元/m³):
                        </td>
                        <td colspan="2">
                            <el-input placeholder="请输入" v-model="formData.transport_price" :disabled="vueFormParams.IsRead"></el-input>
                        </td>
                    </template> -->
                    </tr>
                    <tr>
                        <td class="column-tit">
                            基础运费(元/m³):
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.base_freight"
                                :disabled="vueFormParams.IsRead"
                                type="number"
                                @mousewheel.native.prevent
                            ></el-input>
                        </td>
                        <td class="column-tit">
                            基础公里数(km):
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.base_mileage"
                                :disabled="vueFormParams.IsRead"
                                type="number"
                                @mousewheel.native.prevent
                            ></el-input>
                        </td>
                        <td class="column-tit">
                            超出运距费用(元/m³·km):
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.than_distance"
                                :disabled="vueFormParams.IsRead"
                                type="number"
                                @mousewheel.native.prevent
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            费用补充模式：
                        </td>
                        <td colspan="2">
                            <el-radio-group v-model="formData.compensate_charge_way" :disabled="vueFormParams.IsRead">
                                <el-radio :label="1">
                                    补方
                                </el-radio>
                                <el-radio :label="2">
                                    补固定金额
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <template v-if="formData.compensate_charge_way===1">
                            <td class="column-tit" colspan="3">
                                每车不足<el-input
                                    placeholder="请输入"
                                    v-model="formData.compensate_capacity"
                                    :disabled="vueFormParams.IsRead"
                                    type="number"
                                    @mousewheel.native.prevent
                                    class="line-input"
                                ></el-input> m³，
                                按<el-input
                                    placeholder="请输入"
                                    v-model="formData.bv_volumn_add"
                                    :disabled="vueFormParams.IsRead"
                                    class="line-input"
                                    type="number"
                                    @mousewheel.native.prevent
                                ></el-input>m³收取运费。
                            </td>
                        </template>
                        <template v-if="formData.compensate_charge_way===2">
                            <td colspan="3">
                                单次方不足<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.bp_volumn_less"
                                    type="number"
                                    @mousewheel.native.prevent
                                ></el-input>
                                m³,按实际方量价格结算后加收<el-input
                                    placeholder="请输入"
                                    class="price-input"
                                    v-model="formData.plus_money"
                                    type="number"
                                    @mousewheel.native.prevent
                                ></el-input>元。
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td colspan="1" class="column-tit">
                            对方自运(元/m³)
                        </td>
                        <td colspan="5">
                            <el-input
                                placeholder="请输入"
                                type="number"
                                @mousewheel.native.prevent
                                v-model="formData.transport_price"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
            height="30%"
            :modal="false"
            center
        >
            <ul>
                <li
                    class="tag el_left"
                    v-for="tag in currentNumMore.additionalList"
                    :key="`more${tag.cpaiid}_${tag.cpmiid}`"
                >
                    <span class="tag-text" :title="tag.additional_model">{{ tag.additional_model }}</span>
                    <span
                        class="iconfont iconguanbi"
                        @click="delLinkSingle(tag)"
                        v-if="!vueFormParams.IsRead"
                    ></span>
                </li>
            </ul>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            formData: {
                ContractPriceMarkNumList: [],
                ContractPriceAdditionalList: [],
                ContractPriceAdditionalMarkList: [],
                ContractPricePumpList: [],
            }, // 总数据集合
            productNumId: 0, // 标号序列号
            currentNumData: {}, // 当前标号数据
            additionalNumId: 0, // 附加型号序列号
            currentAdditionalData: {}, // 当前附加型号数据
            productNumChosed: [], // 已添加的标号
            pumpNumId: 0, // 泵送价格序列号
            currentPumpData: {}, // 当前泵送数据
            dialogVisible: false, // 更多附加型号弹框显示隐藏
            currentNumMore: {}, // 当前要弹出更多型号的标号数据
            // moreAdditionalList: [], // 更多附加型号弹框数据
            dialogTitle: '', // 更多附加型号标题
            pumpingHeightSelectData: [], // 泵送字典数据列表
            IsRead: false,
        };
    },
    watch: {
    },
    computed: {},
    methods: {
        // 附加型号-关联对应标号
        checkProductNum(additionalNum, markNum, event) {
            if (event) {
                const json = {
                    cpamiid: '',
                    cpaiid: additionalNum.cpaiid,
                    additional_model: additionalNum.additional_model,
                    additional_model_code: additionalNum.additional_model_code,
                    cpmiid: markNum.cpmiid,
                    product_mark_num: markNum.product_mark_num,
                };
                const res = this.findInLink(json);
                if (res === -1) {
                    this.formData.ContractPriceAdditionalMarkList.push(json);
                    this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
                    // 选中的时候其他相同型号的为不可选
                    this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                        if (item.productNumChosed.length === item.checkedProductNumList.length) {
                            item.checkAll = true;
                        }
                        if (item.cpaiid !== additionalNum.cpaiid) {
                            if (item.additional_model_code === json.additional_model_code) {
                                item.productNumChosed = item.productNumChosed.map(v => {
                                    if (v.cpmiid === markNum.cpmiid) {
                                        v.disable = true;
                                    }
                                    return v;
                                });
                                item.checkAllDisable = true;
                            }
                        }
                        return item;
                    });
                }

            } else {
                const cpaiid = additionalNum.cpaiid;
                const cpmiid = markNum.cpmiid;
                let delIndex = 0;
                this.formData.ContractPriceAdditionalMarkList.map((item, index) => {
                    if (item.cpaiid === cpaiid && item.cpmiid === cpmiid) {
                        delIndex = index;
                        // this.delAdditionalmark(item);
                    }
                });
                this.formData.ContractPriceAdditionalMarkList.splice(delIndex, 1);
                this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
                // 取消选中的时候其他相同型号的为可选
                this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                    if (item.cpaiid !== additionalNum.cpaiid) {
                        if (item.additional_model_code === additionalNum.additional_model_code) {
                            item.productNumChosed = item.productNumChosed.map(v => {
                                if (v.cpmiid === markNum.cpmiid) {
                                    v.disable = false;
                                }
                                return v;
                            });
                            const arr = item.productNumChosed.filter(num => num.disable === true);
                            if (arr.length > 0) {
                                item.checkAllDisable = true;
                            } else {
                                item.checkAllDisable = false;
                            }
                        }
                    }
                    return item;
                });
            }
            // if (additionalNum.checkedProductNumList.length === this.formData.ContractPriceMarkNumList.length) {
            //     additionalNum.checkAll = true;
            // } else {
            //     additionalNum.checkAll = false;
            // }
        },
        // 切换附加型号是否挂在所有标号下
        // eslint-disable-next-line no-unused-vars
        handleCheckAll(data, event) {
            if (data.checkAll) {
                if (data.additional_model) {
                    const b = this.additionalAllNum(data);
                    data.checkAll = b;
                    event = b;
                } else {
                    data.checkAll = false;
                    this.$message.warning('请先选取附加型号!');
                }
            } else {
                data.checkedProductNumList = [];
                this.additionalDelAll(data);
            }
            this.$forceUpdate();
        },
        // 获取价格表
        getContractPrice() {
            let queryUrl = '';
            if (this.vueFormParams.businessInstanceId) {
                queryUrl += '?businessInstanceId=' + this.vueFormParams.businessInstanceId;
                if (this.vueFormParams.PKValues) {
                    queryUrl += '&PKValues=' + this.vueFormParams.PKValues;
                }
                if (this.vueFormParams.code) {
                    if (this.vueFormParams.code.term) {
                        const originalContractVersionId = this.$queryString(this.vueFormParams.code.term, 'originalContractVersionId');
                        if (originalContractVersionId) {
                            queryUrl += '&originalContractVersionId=' + originalContractVersionId;
                        }
                    }
                }
            } else if (this.vueFormParams.PKValues) {
                queryUrl += '?PKValues=' + this.vueFormParams.PKValues;
                if (this.vueFormParams.code) {
                    if (this.vueFormParams.code.term) {
                        const originalContractVersionId = this.$queryString(this.vueFormParams.code.term, 'originalContractVersionId');
                        if (originalContractVersionId) {
                            queryUrl += '&originalContractVersionId=' + originalContractVersionId;
                        }
                    }
                }
            } else if (this.vueFormParams.code) {
                if (this.vueFormParams.code.term) {
                    const originalContractVersionId = this.$queryString(this.vueFormParams.code.term, 'originalContractVersionId');
                    if (originalContractVersionId) {
                        queryUrl += '?originalContractVersionId=' + originalContractVersionId;
                    }
                }
            }

            this.$axios
                .get('/interfaceApi/sale/contract_price/instance' + queryUrl)
                .then(res => {
                    if (res) {
                        if (res.business_instance_id === null) {
                            this.vueFormParams.IsRead = true;
                            // 初始化-费用补充方式
                            if (!this.formData.compensate_charge_way) {
                                this.formData.compensate_charge_way = 1;
                            }
                            // 初始化-是否自运
                            if (this.formData.is_self_carriage === null) {
                                this.formData.is_self_carriage = false;
                            }
                            // 初始化-是否含运费
                            if (this.formData.is_included_freight === null) {
                                this.formData.is_included_freight = true;
                            }
                        } else {
                            // this.vueFormParams.IsRead = false;
                            this.formData = res;
                            // 初始化-费用补充方式
                            if (!this.formData.compensate_charge_way) {
                                this.formData.compensate_charge_way = 1;
                            }
                            // 初始化-是否自运
                            if (this.formData.is_self_carriage === null) {
                                this.formData.is_self_carriage = false;
                            }
                            // 初始化-是否含运费
                            if (this.formData.is_included_freight === null) {
                                this.formData.is_included_freight = true;
                            }
                            const chosedArr = JSON.parse(JSON.stringify(this.formData.ContractPriceMarkNumList)); // 新数组
                            this.productNumChosed = chosedArr;
                            if (this.formData.ContractPriceAdditionalMarkList.length > 0) {
                                this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
                                this.productNumChosedAllAdditional();
                                this.renderAdditionalNumcheck();
                                this.otherAdditionalDisable();
                            }
                        }

                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 回显-型号选中标号
        renderAdditionalNumcheck() {
            const linkClassByAdditional = this.linkClassByAdditional(this.formData.ContractPriceAdditionalMarkList);
            this.formData.ContractPriceAdditionalList.map(item => {
                if (linkClassByAdditional[item.cpaiid]) {
                    let arr = [];
                    arr = linkClassByAdditional[item.cpaiid];
                    const list = [];
                    arr.map(v => {
                        list.push(v.cpmiid);
                    });
                    item.checkedProductNumList = list;
                    if (list.length === this.productNumChosed.length) {
                        item.checkAll = true;
                    } else {
                        item.checkAll = false;
                    }
                } else {
                    item.checkAll = false;
                    item.checkedProductNumList = [];
                }
            });
        },
        // 回显-相同附加类型有一个标号选中，其他型号该标号不可选
        otherAdditionalDisable() {
            const json = {};
            this.formData.ContractPriceAdditionalList.map(item => {
                if (!json[item.additional_model_code]) {
                    json[item.additional_model_code] = [];
                    json[item.additional_model_code].push(item);
                } else {
                    json[item.additional_model_code].push(item);
                }
            });
            this.formData.ContractPriceAdditionalList.map(item => {
                item.productNumChosed.map(v => {
                    const position = item.checkedProductNumList.indexOf(v.cpmiid);
                    if (position !== -1) {
                        json[item.additional_model_code] = json[item.additional_model_code].map(otherItem => {
                            if (otherItem.cpaiid !== item.cpaiid) {
                                otherItem.productNumChosed = otherItem.productNumChosed.map(num => {
                                    if (num.cpmiid === v.cpmiid) {
                                        num.disable = true;
                                        otherItem.checkAllDisable = true;
                                    }
                                    return num;
                                });
                            }
                            return otherItem;
                        });
                    }
                });
            });
        },
        // 回显-为所有附加型号添加所有标号
        productNumChosedAllAdditional() {
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                let arr = JSON.parse(JSON.stringify(this.productNumChosed));
                arr = arr.map(v => {
                    v.disable = false;
                    return v;
                });
                item.productNumChosed = arr;
                return item;
            });
        },
        // 添加产品标号（新增一行空数据）
        addNum() {
            this.productNumId++;
            const json = {
                cpmiid: 'productNum' + this.productNumId,
                product_mark_code: '',
                product_mark_num: '',
                product_mark_price: 0,
                additionalList: [], // 自定义-附加型号集合
            };
            this.formData.ContractPriceMarkNumList.push(json);
        },
        // 选取产品标号（弹出列表）
        choseNumList() {
            let choosed_code = '';
            this.productNumChosed.map(item => {
                choosed_code += item.product_mark_code + ',';
            });
            // this.currentNumData = item;
            this.$toast(
                {
                    title: true,
                    text: '选择产品标号',
                    type: 'eject',
                    t_url: 'tableList/index',
                    extr: {
                        code: { TableCode: 'choose_product_mark', QueryString: 'area_id=' + this.formData.area_id + '&choosed_code=' + choosed_code },
                        sureCallback: this.productNumCallback,
                    },
                }
            );
        },
        // 选取产品标号（完成赋值）
        productNumCallback(data) {
            window.hideToast();
            if (data) {
                // this.formData.ContractPriceMarkNumList = this.formData.ContractPriceMarkNumList.filter(
                //     item => item.cpmiid.indexOf('productNum') === -1);
                data.map(item => {
                    const obj = {
                        cpmiid: item.id,
                        product_mark_code: item.smallclass_code,
                        product_mark_num: item.smallclass_name,
                        product_mark_price: item.attr_price,
                        disable: false,
                        sort_num: item.sort_num,
                    };
                    const arr = this.formData.ContractPriceMarkNumList.filter(num => num.product_mark_code === obj.product_mark_code);
                    if (arr.length < 1) {
                        this.formData.ContractPriceMarkNumList.push(obj);
                        this.formData.ContractPriceMarkNumList.sort((a, b) => {
                            return a.sort_num - b.sort_num;
                        });
                        this.productNumChosed = this.formData.ContractPriceMarkNumList;
                        this.findAllAdditional(obj);
                        this.addNumToAllAdditional(obj);
                    }
                });
            }
        },
        // 添加所有标号的附加型号
        findAllAdditional(obj) {
            let allArr = [];
            allArr = this.formData.ContractPriceAdditionalList.filter(item => {
                let b = false;
                if (item.cpaiid.indexOf('additional') === -1 && item.checkAll) {
                    b = true;
                } else {
                    b = false;
                }
                return b;
            });
            let numData = {};
            numData = obj;
            allArr.map(item => {
                const json = {
                    cpamiid: '',
                    cpaiid: item.cpaiid,
                    additional_model: item.additional_model,
                    additional_model_code: item.additional_model_code,
                    cpmiid: numData.cpmiid,
                    product_mark_num: numData.product_mark_num,
                };
                const res = this.findInLink(json);
                if (res === -1) {
                    this.formData.ContractPriceAdditionalMarkList.push(json);
                }
            });
            this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
        },
        // 所有附加型号的标号集合新增标号
        addNumToAllAdditional(numData) {
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                if (item.checkAll) {
                    item.productNumChosed.push(JSON.parse(JSON.stringify(numData)));
                    item.checkedProductNumList.push(numData.cpmiid);
                } else {
                    const json = {
                        cpamiid: '',
                        cpaiid: item.cpaiid,
                        additional_model: item.additional_model,
                        additional_model_code: item.additional_model_code,
                        cpmiid: numData.cpmiid,
                        product_mark_num: numData.product_mark_num,
                    };
                    const res = this.findInLink(json);
                    if (res === -1) {
                        item.productNumChosed.push(JSON.parse(JSON.stringify(numData)));
                    } else {
                        const obj = JSON.parse(JSON.stringify(numData));
                        obj.disable = true;
                        item.productNumChosed.push(obj);
                    }
                }
                return item;
            });
        },
        // 查看更多附加型号
        viewMoreTag(data) {
            this.dialogTitle = data.product_mark_num + '-附加型号';
            this.dialogVisible = true;
            this.currentNumMore = data;
        },
        // 删除标号
        delMarkNum(data) {
            this.formData.ContractPriceMarkNumList = this.formData.ContractPriceMarkNumList.filter(item => item.cpmiid !== data.cpmiid);
            if (data.cpmiid) {
                const linkData = this.formData.ContractPriceAdditionalMarkList;
                this.formData.ContractPriceAdditionalMarkList = linkData.filter(item => item.cpmiid !== data.cpmiid);
                this.productNumChosed = this.productNumChosed.filter(item => item.cpmiid !== data.cpmiid);
                const res = this.linkClassByAdditional(this.formData.ContractPriceAdditionalMarkList);
                this.restAdditionalList(res);
            }
            // 标号删除后，删除所有附加型号下的对应标号
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                // eslint-disable-next-line prefer-const
                let arr = item.productNumChosed.filter(v => v.cpmiid !== data.cpmiid);
                item.productNumChosed = JSON.parse(JSON.stringify(arr));
                return item;
            });
        },
        // 删除型号
        delAdditional(data) {
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.filter(item => item.cpaiid !== data.cpaiid);
            const linkData = this.formData.ContractPriceAdditionalMarkList;
            this.formData.ContractPriceAdditionalMarkList = linkData.filter(item => item.cpaiid !== data.cpaiid);
            this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
            // 该型号下所有选中的标号，其他同类型的附加型号变成可选
            if (data.checkAll) {
                data.productNumChosed.map(v => {
                    this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                        if (item.additional_model_code === data.additional_model_code) {
                            item.productNumChosed = item.productNumChosed.map(num => {
                                if (num.cpmiid === v.cpmiid) {
                                    num.disable = false;
                                }
                                return num;
                            });
                            const arr = item.productNumChosed.filter(num => num.disable === true);
                            if (arr.length > 0) {
                                item.checkAllDisable = true;
                            } else {
                                item.checkAllDisable = false;
                            }
                        }
                        return item;
                    });
                });

            } else {
                data.checkedProductNumList.map(v => {
                    this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                        if (item.additional_model_code === data.additional_model_code) {
                            item.productNumChosed = item.productNumChosed.map(num => {
                                if (num.cpmiid === v) {
                                    num.disable = false;
                                }
                                return num;
                            });
                            const arr = item.productNumChosed.filter(num => num.disable === true);
                            if (arr.length > 0) {
                                item.checkAllDisable = true;
                            } else {
                                item.checkAllDisable = false;
                            }
                        }
                        return item;
                    });
                });
            }
        },
        // 单个删除某个标号的某个附加型号
        delLinkSingle(tag) {
            const res = this.findInLink(tag);
            this.formData.ContractPriceAdditionalMarkList.splice(res, 1);
            this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
            const linkClassByAdditional = this.linkClassByAdditional(this.formData.ContractPriceAdditionalMarkList);
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                if (item.cpaiid === tag.cpaiid) {
                    item.checkAll = false;
                    let arr = [];
                    if (linkClassByAdditional[tag.cpaiid]) {
                        arr = linkClassByAdditional[tag.cpaiid];
                    } else {
                        arr = [];
                    }
                    const list = [];
                    arr.map(v => {
                        list.push(v.cpmiid);
                    });
                    item.checkedProductNumList = list;
                }
                return item;
            });
            this.delLinkSingleRestDisable(tag);
        },
        // 添加附加型号
        addAdditional() {
            this.additionalNumId++;
            // eslint-disable-next-line prefer-const
            let productNum = JSON.parse(JSON.stringify(this.productNumChosed));
            // eslint-disable-next-line prefer-const
            let json = {
                cpaiid: 'additional' + this.additionalNumId,
                additional_model_code: '',
                additional_model: '',
                additional_model_price: 0,
                checkAll: true, // 自定义-是否是所有型号
                checkedProductNumList: [], // 自定义-选中的标号集合
                productNumChosed: productNum,
                checkAllDisable: true,
            };
            this.formData.ContractPriceAdditionalList.push(json);
        },
        // 选取附加型号（弹出列表）
        choseAdditional() {
            // this.currentAdditionalData = item;
            this.$toast(
                {
                    title: true,
                    text: '选择附加型号',
                    type: 'eject',
                    width: '14.3rem',
                    height: '90%',
                    t_url: 'salesBusiness/selectModel/index',
                    extr: {
                        area_id: this.formData.area_id,
                        sureCallback: this.additionalCallback,
                        multipleCallBack: this.multipleCallBack,
                    },
                }
            );
        },
        // 选取附加型号（完成赋值）- 组合选择
        additionalCallback(data) {
            window.hideToast();
            if (data) {
                const productNum = JSON.parse(JSON.stringify(this.productNumChosed));
                if (data.length > 1) {
                    const id = data[0].id;
                    let smallclass_code = '';
                    let smallclass_name = '';
                    let attr_price = 0;
                    let sort_num = 0;
                    data.map(item => {
                        smallclass_code += item.smallclass_code + ',';
                        smallclass_name += item.smallclass_name + '+';
                        if (item.attr_price) {
                            attr_price += item.attr_price;
                        }
                        if (item.sort_num) {
                            sort_num += item.sort_num;
                        }
                    });
                    smallclass_code = smallclass_code.substring(0, smallclass_code.length - 1);
                    smallclass_code = smallclass_code.split(',').sort().join();
                    smallclass_name = smallclass_name.substring(0, smallclass_name.length - 1);
                    const obj = {
                        cpaiid: id,
                        additional_model_code: smallclass_code,
                        additional_model: smallclass_name,
                        additional_model_price: attr_price,
                        checkAllDisable: false,
                        checkAll: true, // 自定义-是否是所有型号
                        checkedProductNumList: [], // 自定义-选中的标号集合
                        productNumChosed: productNum,
                        sort_num: sort_num,
                        use_count: data[0].use_count,
                    };
                    const arr = this.formData.ContractPriceAdditionalList.filter(num => num.additional_model_code === obj.additional_model_code);
                    if (arr.length < 1) {
                        this.formData.ContractPriceAdditionalList.push(obj);
                        this.formData.ContractPriceAdditionalList.sort((a, b) => {
                            if (a.use_count === b.use_count ) {
                                return a.sort_num - b.sort_num;
                            }
                            return b.use_count - a.use_count;
                        });
                        this.additionalAllNumExclusion(obj);
                    } else {
                        this.$message({
                            message: `${smallclass_name}已经选择过了~`,
                            type: 'warning',
                        });
                    }
                } else {
                    const obj = {
                        cpaiid: data[0].id,
                        additional_model_code: data[0].smallclass_code,
                        additional_model: data[0].smallclass_name,
                        additional_model_price: data[0].attr_price,
                        checkAllDisable: false,
                        checkAll: true, // 自定义-是否是所有型号
                        checkedProductNumList: [], // 自定义-选中的标号集合
                        productNumChosed: productNum,
                        sort_num: data[0].sort_num,
                        use_count: data[0].use_count,
                    };
                    const arr = this.formData.ContractPriceAdditionalList.filter(num => num.additional_model_code === obj.additional_model_code);
                    if (arr.length < 1) {
                        this.formData.ContractPriceAdditionalList.push(obj);
                        this.formData.ContractPriceAdditionalList.sort((a, b) => {
                            if (a.use_count === b.use_count ) {
                                return a.sort_num - b.sort_num;
                            }
                            return b.use_count - a.use_count;
                        });
                        this.additionalAllNumExclusion(obj);
                    }
                }
            }
        },
        // 选取附加型号（完成赋值）- 批量选择
        multipleCallBack(data) {
            // 先判断有没有已经选择过的
            const opt = {
                arr: this.formData.ContractPriceAdditionalList,
                targetArr: data,
                prop: 'additional_model_code',
                targetProp: 'smallclass_code',
                msgProp: 'smallclass_name',
            };
            data = this.checkRemoveRepeat(opt);
            window.hideToast();
            // this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.filter(
            //     item => item.cpaiid.indexOf('additional') === -1);
            data.map(item => {
                const productNum = JSON.parse(JSON.stringify(this.productNumChosed));
                const obj = {
                    cpaiid: item.id,
                    additional_model_code: item.smallclass_code,
                    additional_model: item.smallclass_name,
                    additional_model_price: item.attr_price,
                    checkAll: true, // 自定义-是否是所有型号
                    checkedProductNumList: [], // 自定义-选中的标号集合
                    productNumChosed: productNum,
                    checkAllDisable: false,
                    sort_num: item.sort_num,
                    use_count: item.use_count,
                };
                const arr = this.formData.ContractPriceAdditionalList.filter(num => num.additional_model_code === obj.additional_model_code);
                if (arr.length < 1) {
                    this.formData.ContractPriceAdditionalList.push(obj);
                    this.formData.ContractPriceAdditionalList.sort((a, b) => {
                        if (a.use_count === b.use_count ) {
                            return a.sort_num - b.sort_num;
                        }
                        return b.use_count - a.use_count;
                    });
                    this.additionalAllNumExclusion(obj);
                }
            });
        },
        // 判断附加型号是否已选
        additionalAllNumExclusion(additional) {
            let findIndex = -1;
            this.formData.ContractPriceAdditionalMarkList.map((item, index) => {
                if (item.additional_model_code === additional.additional_model_code) {
                    findIndex = index;
                }
            });
            if (findIndex !== -1) {
                this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                    if (item.cpaiid === additional.cpaiid) {
                        item.checkAll = false;
                        this.exclusionAdditionalNum(item);
                    }
                    return item;
                });
            } else {
                this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                    if (item.cpaiid === additional.cpaiid) {
                        item.checkAll = true;
                        this.additionalAllNum(item);
                    }
                    return item;
                });
            }
        },
        // 附加型号重置标号集合
        additionalNumList(productNumChosed) {
            let [...arr] = productNumChosed;
            arr = arr.map(item => {
                item.disable = false;
                return item;
            });
            const arr2 = [];
            for (let i = 0; i < this.formData.ContractPriceAdditionalList.length; i++) {
                arr2.push(arr);
            }
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map((item, index) => {
                const obj = arr2[index];
                item.productNumChosed = obj;
            });
        },
        // 渲染当前型号下的标号是否可选
        exclusionAdditionalNum(data) {
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                if (item.cpaiid === data.cpaiid) {
                    item.productNumChosed = item.productNumChosed.map(v => {
                        const res = this.findAdditional(data, v);
                        if (res !== -1) {
                            v.disable = true;
                            item.checkAllDisable = true;
                        }
                        return v;
                    });
                }
                return item;
            });
        },
        findAdditional(additional, num) {
            let findIndex = -1;
            this.formData.ContractPriceAdditionalMarkList.map((item, index) => {
                if (item.additional_model_code === additional.additional_model_code && item.cpmiid === num.cpmiid) {
                    findIndex = index;
                }
            });
            return findIndex;

        },
        delLinkSingleRestDisable(tag) {
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                if (item.additional_model_code === tag.additional_model_code) {
                    item.productNumChosed = item.productNumChosed.map(v => {
                        if (v.cpmiid === tag.cpmiid) {
                            v.disable = false;
                        }
                        return v;
                    });
                    const arr = item.productNumChosed.filter(num => num.disable === true);
                    if (arr.length > 0) {
                        item.checkAllDisable = true;
                    } else {
                        item.checkAllDisable = false;
                    }
                }
                return item;
            });
        },
        // 某个附加型号添加所有标号-关系集合
        additionalAllNum(data) {
            let b = false;
            data.productNumChosed.map(item => {
                if (!item.disable) {
                    const json = {
                        cpamiid: '',
                        cpaiid: data.cpaiid,
                        additional_model: data.additional_model,
                        additional_model_code: data.additional_model_code,
                        cpmiid: item.cpmiid,
                        product_mark_num: item.product_mark_num,
                    };
                    const res = this.findInLink(json);
                    if (res === -1) {
                        data.checkedProductNumList.push(item.cpmiid);
                        this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(v => {
                            if (v.cpaiid !== data.cpaiid) {
                                if (v.additional_model_code === data.additional_model_code) {
                                    v.productNumChosed = v.productNumChosed.map(num => {
                                        if (num.cpmiid === item.cpmiid) {
                                            num.disable = true;
                                        }
                                        return num;
                                    });
                                    const arr = v.productNumChosed.filter(numitem => numitem.disable === true);
                                    if (arr.length > 0) {
                                        v.checkAllDisable = true;
                                    } else {
                                        v.checkAllDisable = false;
                                    }
                                }
                            }
                            return v;
                        });
                        this.formData.ContractPriceAdditionalMarkList.push(json);
                    }
                }
            });
            if (data.checkedProductNumList.length === data.productNumChosed.length) {
                b = true;
            } else {
                b = false;
            }
            this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
            return b;
        },
        // 查找关系集合是否已存在
        findInLink(json) {
            let findIndex = -1;
            this.formData.ContractPriceAdditionalMarkList.map((item, index) => {
                if (item.additional_model_code === json.additional_model_code && item.cpmiid === json.cpmiid) {
                    findIndex = index;
                }
            });
            return findIndex;
        },
        // 某个附加型号-从所有标号中删除
        additionalDelAll(item) {
            const cpaiid = item.cpaiid;
            this.formData.ContractPriceAdditionalMarkList = this.formData.ContractPriceAdditionalMarkList.filter(v => v.cpaiid !== cpaiid);
            this.linkClassByNum(this.formData.ContractPriceAdditionalMarkList);
            // 同一个型号都变成可选
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(v => {
                if (v.additional_model_code === item.additional_model_code) {
                    v.productNumChosed = v.productNumChosed.map(num => {
                        num.disable = false;
                        return num;
                    });
                    v.checkAllDisable = false;
                }
                return v;
            });
        },
        // 关系集合-按标号分组
        linkClassByNum(oldArray) {
            if (oldArray.length > 0) {
                const linkByNumData = {};
                for (const item of oldArray) {
                    if (!linkByNumData[item.cpmiid]) {
                        linkByNumData[item.cpmiid] = [];
                        linkByNumData[item.cpmiid].push(item);
                    } else {
                        linkByNumData[item.cpmiid].push(item);
                    }
                }
                this.formData.ContractPriceMarkNumList = this.formData.ContractPriceMarkNumList.map(item => {
                    if (linkByNumData[item.cpmiid]) {
                        item.additionalList = linkByNumData[item.cpmiid];
                    } else {
                        item.additionalList = [];
                    }
                    return item;
                });
            } else {
                this.formData.ContractPriceMarkNumList = this.formData.ContractPriceMarkNumList.map(item => {
                    item.additionalList = [];
                    return item;
                });
            }

        },
        // 关系集合-按型号分组
        linkClassByAdditional(oldArray) {
            let linkByAdditionalData = {};
            if (oldArray.length > 0) {
                for (const item of oldArray) {
                    if (!linkByAdditionalData[item.cpaiid]) {
                        linkByAdditionalData[item.cpaiid] = [];
                        linkByAdditionalData[item.cpaiid].push(item);
                    } else {
                        linkByAdditionalData[item.cpaiid].push(item);
                    }
                }
            } else {
                linkByAdditionalData = {};
            }
            return linkByAdditionalData;
        },
        // 重置型号下挂载的标号
        restAdditionalList(linkClassByAdditional) {
            this.formData.ContractPriceAdditionalList = this.formData.ContractPriceAdditionalList.map(item => {
                item.checkAll = false;
                let arr = [];
                if (linkClassByAdditional[item.cpaiid]) {
                    arr = linkClassByAdditional[item.cpaiid];
                } else {
                    arr = [];
                }
                const list = [];
                arr.map(v => {
                    list.push(v.cpmiid);
                });
                item.checkedProductNumList = list;
                if (list.length === this.productNumChosed.length) {
                    item.checkAll = true;
                    item.checkAllDisable = false;
                }
                return item;
            });
        },
        // 删除合同产品标号与附加型号关系
        delAdditionalmark(data) {
            if (data.cpamiid) {
                this.$axios
                    .delete('/interfaceApi/sale/contract_price/instance/additionalmark/' + data.cpamiid)
                    .then(() => {
                        this.$message.success('删除成功');
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 添加泵送价格型号
        addPumpList() {
            this.pumpNumId++;
            const json = {
                cppiid: 'pump' + this.pumpNumId,
                pump_height_code: '',
                pump_height_name: '',
                pump_upper_code: '',
                pump_lower_code: '',
                pump_upper: '',
                pump_lower: '',
                pump_price: 0,
            };
            this.formData.ContractPricePumpList.push(json);
        },
        // 选取泵送价格（弹出列表）
        chosePumpList() {
            // this.currentPumpData = item;
            this.$toast(
                {
                    title: true,
                    text: '选择泵送价格',
                    type: 'eject',
                    t_url: 'tableList/index',
                    extr: {
                        code: { TableCode: 'choose_pump_height', QueryString: 'area_id=' + this.formData.area_id },
                        sureCallback: this.addPumpListCallback,
                    },
                }
            );
        },
        // 选取泵送价格（完成赋值）
        addPumpListCallback(data) {
            window.hideToast();
            if (data) {
                // this.formData.ContractPricePumpList = this.formData.ContractPricePumpList.filter(
                //     item => item.cppiid.indexOf('pump') === -1);
                data.map(item => {
                    const obj = {
                        cppiid: item.id,
                        // pump_height_code: item.smallclass_code,
                        pump_height_name: item.pump_height_name,
                        pump_upper_code: item.pump_upper_code,
                        pump_lower_code: item.pump_lower_code,
                        pump_upper: item.pump_upper,
                        pump_lower: item.pump_lower,
                        pump_price: item.pump_price,
                        sort_num: item.sort_num,
                    };
                    const arr = [];
                    this.formData.ContractPricePumpList.map(num => {
                        if (obj.pump_upper === num.pump_upper && obj.pump_lower === num.pump_lower) {
                            arr.push(num);
                        }
                    });
                    if (arr.length < 1) {
                        this.formData.ContractPricePumpList.push(obj);
                        this.formData.ContractPricePumpList.sort((a, b) => {
                            return a.sort_num - b.sort_num;
                        });
                    }
                });
            }
        },
        // 删除泵送价格
        delPumpPrice(data) {
            this.formData.ContractPricePumpList = this.formData.ContractPricePumpList.filter(item => item.cppiid !== data.cppiid);
        },
        // 获取泵送价格字典
        getPumpList() {
            this.$axios.get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=303-BS0&type=3')
                .then(res => {
                    if (res) {
                        this.pumpingHeightSelectData = res;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 判断是否可选
        judgePumpDisable(lower, k) {
            let b = false;
            // eslint-disable-next-line radix
            if (parseInt(k.name) <= parseInt(lower)) {
                b = true;
            }
            return b;
        },
        // 保存
        save(successCallBack, errorCallBack) {
            // let isPriceEmpty = true;
            const formData = JSON.parse(JSON.stringify(this.formData));
            formData.ContractPriceMarkNumList = formData.ContractPriceMarkNumList.map(item => {
                const json = {};
                json.cpmiid = item.cpmiid;
                json.product_mark_code = item.product_mark_code;
                json.product_mark_num = item.product_mark_num;
                json.product_mark_price = item.product_mark_price;
                if (item.sort_num) {
                    json.sort_num = item.sort_num;
                }

                return json;
            });
            const emptyPriceArr = [];
            for (let i = 0; i < formData.ContractPriceMarkNumList.length; i++) {
                const item = formData.ContractPriceMarkNumList[i];
                if (item.product_mark_price) {
                    if (Number(item.product_mark_price) === 0) {
                        emptyPriceArr.push(item);
                    }
                } else {
                    emptyPriceArr.push(item);
                }

            }

            formData.ContractPriceAdditionalList = formData.ContractPriceAdditionalList.map(item => {
                const json = {};
                json.cpaiid = item.cpaiid;
                json.additional_model_code = item.additional_model_code;
                json.additional_model = item.additional_model;
                json.additional_model_price = item.additional_model_price;
                if (item.sort_num) {
                    json.sort_num = item.sort_num;
                }
                return json;
            });
            formData.ContractPriceAdditionalMarkList = formData.ContractPriceAdditionalMarkList.map(item => {
                const json = {};
                json.cpamiid = item.cpamiid;
                json.cpaiid = item.cpaiid;
                json.additional_model_code = item.additional_model_code;
                json.additional_model = item.additional_model;
                json.cpmiid = item.cpmiid;
                json.product_mark_code = item.product_mark_code;
                json.product_mark_num = item.product_mark_num;
                return json;
            });
            if (emptyPriceArr.length < 1) {
                this.$axios.post('/interfaceApi/sale/contract_price/instance', formData)
                    .then(res => {
                        if (res) {
                            successCallBack(res);
                            this.formData.cpiid = res;
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                        errorCallBack();
                    });
            } else {
                errorCallBack();
                this.$message.warning('标号价格不能为空或者不能为0！');
            }

        },
        /**
             * 判断有没有选择过  比较两个数组有没有重复键值
             * @param arr  数组
             * @param targetArr  目标数组
             * @param prop  数组比较的对象属性
             * @param targetProp    目标比较的数组对象属性
             * @param msgProp   重复提示消息
             * @returns {[]}
             */
        checkRemoveRepeat({ arr, targetArr, prop, targetProp, msgProp }) {
            // 先判断有没有已经选择过的
            const result = [];
            const haveSelectCode = [];// 已经选择的标识数组
            let msg = ''; // 重复提示消息
            if (arr && targetArr) {
                arr.forEach(selectItem => {
                    haveSelectCode.push(selectItem[prop]);
                });
                targetArr.forEach(item => {
                    let tarProps = '';
                    if (targetProp === 'compose_code') {
                        tarProps = [item[targetProp]];
                    } else {
                        tarProps = item[targetProp].split(',').sort().join();
                    }
                    if (tarProps.length > 1) {
                        let haveRepeat = false;
                        for (let str of haveSelectCode) {
                            str = str.split(',').sort().join();
                            if (tarProps === str) {
                                haveRepeat = true;
                                break;
                            }
                        }
                        if (haveRepeat) {
                            msg += item[msgProp] + ',';
                        } else {
                            result.push(item);
                        }
                    } else if (haveSelectCode.indexOf(item[targetProp]) === -1) {
                        result.push(item);
                    } else {
                        msg += item[msgProp] + ',';
                    }
                });
            }
            if (msg) {
                msg = msg.substring(0, msg.length - 1);
                this.$message({
                    message: `${msg}已经选择过了~`,
                    type: 'warning',
                });
            }
            return result;
        },
    },
    created() {
        if (this.vueFormParams.formId) {
            this.vueFormParams.PKValues = this.vueFormParams.formId;
        }
        this.getContractPrice();
        // 获取泵送字典价格
        this.getPumpList();
    },
    mounted() {},
};
</script>
<style lang="stylus">
    .contractPriceForm
        padding .2rem!important
        // width 100%!important
        height 100%!important
        background #fff!important
        overflow-y auto!important
        .el-dialog__header
            background #4f67ad
            color #fff
            padding 10px 20px 10px
            .el-dialog__title
                color #fff
            .el-dialog__headerbtn
                top 10px
                color #fff
                font-size 18px
                .el-dialog__close
                    color #fff
        .el-dialog
            height 300px
            .el-dialog__body
                height calc(100% - 52px)
                overflow-y auto
                overflow-x hidden
        .tag
            width .82rem
            border 1px solid #EAEAEA
            height .32rem
            line-height .32rem
            margin-left .1rem
            color #333
            cursor pointer
            background #F7F7F7
            margin-bottom .1rem
            margin-top: .1rem;
            .tag-text
                float left
                width calc(100% - .24rem)
                white-space nowrap
                text-overflow ellipsis
                overflow hidden
                padding 0 .05rem
            .iconguanbi
                font-size .12rem
                color #979EA7
                float right
                margin-right .1rem
                width .14rem
            &:hover
                background #FEF8F8
                border 1px solid #D6000F
                color #D6000F
                .iconguanbi
                    color #D6000F
        .table-container
            width 100%
            margin-bottom .2rem
            .custom-table
                border 1px solid #D7D7D7
                border-spacing 0px
                border-collapse collapse
                width 100%
                input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button
                -webkit-appearance none !important
                .pump-select
                    .el-select
                        width 1rem
                        input
                            border 1px solid #DCDFE6 !important
                            height .32rem
                            line-height .32rem
                        .el-input__icon
                            line-height .32rem!important
                .text-center
                    text-align center
                td,th
                    border 1px solid #D7D7D7
                    border-collapse collapse
                    height .42rem
                    line-height .42rem
                    font-size .16rem;
                    padding-left .1rem
                    input
                        width 100%
                        height 100%
                        font-size 0.14rem
                        height 0.42rem
                        line-height 0.42rem
                        border none
                    .is-disabled
                        .el-input__inner
                            background-color unset
                            color #333
                        .el-radio__inner
                            background-color: #fff;
                            border: 1px solid #dcdfe6;
                        &.is-checked
                            .el-radio__inner::after
                                background-color: #fff;
                    .is-checked
                        .el-radio__inner
                            border-color: #409eff;
                            background: #409eff;
                    .el-radio__input.is-disabled+span.el-radio__label
                        color #333
                    .price-input
                        width 1rem
                        input
                            width 1rem
                            height .32rem
                            border 1px solid #D7D7D7
                    .icon009
                        font-size .18rem
                    .no-tag
                        color #C6C6C6
                    .tag-con
                        .tag
                            width .8rem
                            border 1px solid #EAEAEA
                            height .32rem
                            line-height .32rem
                            margin-left .1rem
                            color #333
                            cursor pointer
                            background #F7F7F7
                            .tag-text
                                float left
                                width calc(100% - .24rem)
                                white-space nowrap
                                text-overflow ellipsis
                                overflow hidden
                                padding 0 .05rem
                            .iconguanbi
                                font-size .12rem
                                color #979EA7
                                float right
                                margin-right .1rem
                                width .14rem
                            &:hover
                                background #FEF8F8
                                border 1px solid #D6000F
                                color #D6000F
                                .iconguanbi
                                    color #D6000F
                        .tag-more
                            border 1px solid #0285FE
                            color #0285FE
                            border-radius .04rem
                            background #fff
                            text-align center
                            .tag-more-text
                                width 100%
                            &:hover
                                background #fff
                                border 1px solid #0285FE
                                color #0285FE
                                .iconguanbi
                                    color #0285FE
                    .del-btn
                        width .6rem
                        height .3rem
                        text-align center
                        line-height .3rem
                        color #D6000F
                        border 1px solid #D6000F
                        border-radius .04rem
                        display inline-block
                        cursor pointer
                    .sure-btn
                        width .6rem
                        height .3rem
                        text-align center
                        line-height .3rem
                        color #0285FE
                        border 1px solid #0285FE
                        border-radius .04rem
                        display inline-block
                        cursor pointer
                        margin-right .1rem
                .table-tit
                    background #EDF0F5
                    color #022782
                    font-weight bold
                    text-align center
                .column-tit
                    background #F6F8FC
                    color #022782
                    .line-input
                        width 1rem
                        border 1px solid #d7d7d7
                        margin .05rem
                        line-height .32rem
                        border-radius .05rem
                        input
                            line-height .32rem!important
                            height .32rem!important
            .add-btn
                width 100%
                border 1px dashed #D7D7D7
                height .42rem
                margin-top .1rem
                text-align center
                line-height .4rem
                cursor pointer
                background #FCFCFC
                span
                    font-size .16rem
                    color #979EA7
                    margin-right .05rem

</style>
